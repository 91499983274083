$(document).ready(initPage);

function initPage() {
  function checkScroll() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }

  checkScroll();
  $(window).scroll(function () {
    checkScroll();
  });

  const testimonialsSwiper = new Swiper('.testimonials-swiper', {
    slidesPerView: '1',
    spaceBetween: 30,
    loop: true,
    grabCursor: true,
    pagination: {
      el: '.testimonials-swiper .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });

  const infotabsSwiper = new Swiper('.infotabs-swiper', {
    slidesPerView: '1',
    spaceBetween: 30,
    loop: true,
    grabCursor: true,
  });

  infotabsSwiper.on('slideChange', function () {
    $('.infotabs-tab').removeClass('is-active');
    $(
      '.infotabs-tab[data-tab=' + (infotabsSwiper.realIndex + 1) + ']'
    ).addClass('is-active');
  });

  $('#infotab1').click(function () {
    infotabsSwiper.slideTo(0);
    $('.infotabs-tab').removeClass('is-active');
    $(this).addClass('is-active');
  });
  $('#infotab2').click(function () {
    infotabsSwiper.slideTo(1);
    $('.infotabs-tab').removeClass('is-active');
    $(this).addClass('is-active');
  });
  $('#infotab3').click(function () {
    infotabsSwiper.slideTo(2);
    $('.infotabs-tab').removeClass('is-active');
    $(this).addClass('is-active');
  });

  $('.search-toggler').click(function () {
    $('.header-search').toggleClass('is-active');
  });
  $('.toggler').click(function () {
    $('body').toggleClass('is-mobilemenu');
  });

  $('.header-menu .has-sub-menu').hover(
    function () {
      $(this).addClass('is-hover');
    },
    function () {
      $(this).removeClass('is-hover');
    }
  );

  $('.header-toggler').click(function () {
    $('body').toggleClass('is-mobilemenu');
  });

  $('.faq-item .faq-q').click(function () {
    $(this).parent().toggleClass('is-active');
    $(this).parent().siblings().removeClass('is-active');
    const scrollToPosition =
      $(this).offset().top - $('.header').outerHeight() - 20;
  });
}
